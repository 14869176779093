<template>
  <div class="company-documents-status" v-loading="loading">
    <div class="vue-data-table-default">
      <el-table v-if="!loading" :data="offerLettersData">
        <el-table-column label="Document" prop="title"></el-table-column>
        <el-table-column label="Type" prop="type"></el-table-column>

        <el-table-column label="E-signature">
          <template slot-scope="scope">
            <div class="text-center">
              {{ scope.row.e_signature_status ? "Completed" : "Pending" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Expires At">
          <template slot-scope="scope">
            <div class="text-center">
              <span v-if="scope.row.valid_to">{{
                scope.row.valid_to | moment("MM-DD-YYYY")
              }}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <div class="text-center">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-view"
                @click="viewDoc(scope.row)"
              ></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: "userProfile-UserOfferLetters",
  props: [],

  computed: {
    ...mapGetters("offerLetters", ["getUserOfferLetters"]),
  },
  data() {
    return {
      offerLettersData: [],
      loading: false,
      workflowCode: "",
      documentsDataLoading: false,
      userId: "",
    };
  },
  mounted() {
    this.fetchEmployeeOfferLettersData();
  },
  methods: {
    submit() {},
    async fetchEmployeeOfferLettersData() {
      this.loading = true;
      this.userId = this.$route.params.user_id;
      this.offerLettersData = [];

      await this.$store.dispatch("offerLetter/fetchUserOfferLetters", {
        user_id: this.userId,
      });

      this.loading = false;
    },

    viewDoc(doc) {
      console.log(doc);
    },
  },
  beforeDestroy() {
    this.$store.commit("offerLetters/setUserOfferLetters",null,{root:true})
  }
};
</script>
<style lang="scss">
.company-documents-status {
  min-height: calc(100vh - 190px);
  .el-table {
    tr {
      th {
        &:first-child {
          .cell {
            text-align: left;
          }
        }
      }
      td:last-child {
        .cell {
          .el-button {
            color: blue;
          }
        }
      }
    }
  }
}
</style>