<template>
  <div class="company-documents-status" v-loading="loading">
    <el-button
      class="float-right mb-15"
      type="primary"
      @click="onAddNewDocument"
      icon="el-icon-document"
    >Add Documents</el-button>
    <div class="vue-data-table-default">
      <el-table v-if="!loading" :data="documentsData">
        <el-table-column label="Document" prop="title"></el-table-column>
        <el-table-column label="Type" prop="type"></el-table-column>

        <el-table-column label="E-signature">
          <template slot-scope="scope">
            <div class="text-center">{{scope.row.e_signature_status ? 'Completed':'Pending'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Expires At">
          <template slot-scope="scope">
            <div class="text-center">
              <span v-if="scope.row.valid_to">{{ scope.row.valid_to | moment('MM-DD-YYYY')}}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <div class="text-center">
              <el-button size="mini" type="primary" icon="el-icon-view" @click="viewDoc(scope.row)"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <el-button>Select And Send</el-button>

    <el-button v-if="hasNext" @click="next">Save & Next</el-button>
    <el-button v-else @click="submit">Submit</el-button>-->

    <!-- Add Document Dialog -->
    <el-dialog
      :visible.sync="configureCompanyDocumentsVisible"
      title="Company Documents"
      :fullscreen="true"
      :lock-scroll="true"
      class="document-selection-dialog"
      center
    >
      <div class="document-selection-box">
        <div class="mx-auto">
          <el-row :gutter="30" type="flex" justify="center">
            <el-col :span="16">
              <el-card shadow="never">
                <h3 class="dialog-sub-title blue-2">
                  <i class="el-icon-document-add"></i>
                  <span class="pl-1">Choose Documents</span>
                </h3>
                <el-divider class="mb-05 mt-1"></el-divider>

                <el-form v-model="searchDocumentFormData">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Type">
                        <el-select
                          v-model="searchDocumentFormData.type"
                          @change="getCompanyDocuments"
                        >
                          <el-option label="CUSTOM" value="CUSTOM"></el-option>
                          <el-option label="STATIC" value="STATIC"></el-option>
                          <el-option label="STANDARD" value="STANDARD"></el-option>
                          <el-option label="REQUESTED" value="REQUESTED"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        label="Category"
                        v-if="searchDocumentFormData.type !='STANDARD'"
                      >
                        <el-select
                          v-model="searchDocumentFormData.category_id"
                          @change="getCompanyDocuments"
                          clearable
                        >
                          <el-option
                            v-for="(category,index) of getAllCategories"
                            :key="index"
                            :label="category.name"
                            :value="category._id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Group" v-if="searchDocumentFormData.type !='STANDARD'">
                        <el-select
                          v-model="searchDocumentFormData.group_id"
                          @change="getCompanyDocuments"
                          clearable
                        >
                          <el-option
                            v-for="(group,index) of getAllGroups"
                            :key="index"
                            :label="group.name"
                            :value="group._id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <div class="table-scrollable vue-data-table-default" v-loading="fetchingCompanyDocumentDetails">
                  <el-table
                    v-if="searchDocumentFormData.type !='STANDARD' && getCompanyAllDocuments && getCompanyAllDocuments.data && getCompanyAllDocuments.data.length"
                    :data="getCompanyAllDocuments.data"
                    height="calc(100vh - 320px)"
                  >
                    <el-table-column label="Name" prop="title"></el-table-column>
                    <el-table-column label="Type" prop="type"></el-table-column>
                    <el-table-column label="Category" prop="category.name"></el-table-column>
                    <el-table-column label="Groups">
                      <template slot-scope="scope">{{scope.row.groups.map((e)=>e.name).join(', ')}}</template>
                    </el-table-column>
                    <el-table-column label="Add">
                      <template slot-scope="scope">
                        <div class="text-right">
                          <el-button
                            size="mini"
                            :disabled="isDocExisted(scope.row,scope.row.type)"
                            @click="addDocumentToStep(scope.row,scope.row.type.toLowerCase())"
                          >
                            <i class="el-icon-thumb"></i> Select
                          </el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-table
                    class="justify-center col-2"
                    v-if="searchDocumentFormData.type =='STANDARD' && standardDocumentsList && standardDocumentsList.length"
                    :data="standardDocumentsList"
                  >
                    <el-table-column label="Name" prop="title"></el-table-column>
                    <el-table-column label="Type" class="text-center" prop="type"></el-table-column>
                    <el-table-column label="Add">
                      <template slot-scope="scope">
                        <div class="text-center">
                          <el-button
                            size="mini"
                            type="primary"
                            :disabled="isDocExisted(scope.row,scope.row.type)"
                            @click="addDocumentToStep(scope.row,'standard')"
                          >
                            <i class="el-icon-thumb"></i> Select
                          </el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <div class="submit-block right">
                <el-button id="submitButton" type="success" round @click="doneCompanyUserDocuemnts()">
                  <i class="el-icon-finished"></i>
                  <span>Done</span>
                </el-button>
              </div>
              <div class="selected-docx">
                <h3 class="dialog-sub-title success">
                  <i class="el-icon-document-checked"></i>
                  <span class="pl-1">Selected Documents</span>
                </h3>

                <div v-if="getTotalDocuments(newlySelectedDocuments)">
                  <el-card
                    shadow="never"
                    class="scrollable-min"
                    v-if="newlySelectedDocuments && newlySelectedDocuments.static && newlySelectedDocuments.static.length"
                  >
                    <h4 class="blue-2 card-title">
                      <i class="el-icon-document"></i> Static Documents
                    </h4>
                    <el-divider class="mb-0 mt-0"></el-divider>
                    <div
                      class="each-document"
                      v-for="(doc,index) of newlySelectedDocuments.static"
                      :key="index"
                    >
                      <el-button
                        circle
                        class="float-right"
                        size="mini"
                        type="danger"
                        icon="el-icon-close"
                        @click="removeComapnyDocument('static',index)"
                      ></el-button>
                      <h4>{{doc.title}}</h4>
                    </div>
                  </el-card>
                  <el-card
                    shadow="never"
                    class="scrollable-min"
                    v-if="newlySelectedDocuments && newlySelectedDocuments.custom && newlySelectedDocuments.custom.length"
                  >
                    <h4 class="blue-2 card-title">
                      <i class="el-icon-document"></i> Custom Documents
                    </h4>
                    <el-divider class="mb-0 mt-0"></el-divider>
                    <div
                      class="each-document"
                      v-for="(doc,index) of newlySelectedDocuments.custom"
                      :key="index"
                    >
                      <el-button
                        circle
                        class="float-right"
                        size="mini"
                        type="danger"
                        icon="el-icon-close"
                        @click="removeComapnyDocument('custom',index)"
                      ></el-button>
                      <h4>{{doc.title}}</h4>
                    </div>
                  </el-card>
                  <el-card
                    shadow="never"
                    class="scrollable-min"
                    v-if="newlySelectedDocuments && newlySelectedDocuments.standard && newlySelectedDocuments.standard.length"
                  >
                    <h4 class="blue-2 card-title">
                      <i class="el-icon-document"></i> Standard Documents
                    </h4>
                    <el-divider class="mb-0 mt-0"></el-divider>
                    <div
                      class="each-document"
                      v-for="(doc,index) of newlySelectedDocuments.standard"
                      :key="index"
                    >
                      <el-button
                        circle
                        class="float-right"
                        size="mini"
                        type="danger"
                        icon="el-icon-close"
                        @click="removeComapnyDocument('standard',index)"
                      ></el-button>
                      <h4>{{doc.title}}</h4>
                    </div>
                  </el-card>
                  <el-card
                    shadow="never"
                    class="scrollable-min"
                    v-if="newlySelectedDocuments && newlySelectedDocuments.requested && newlySelectedDocuments.requested.length"
                  >
                    <h4 class="blue-2 card-title">
                      <i class="el-icon-document"></i> Requested Documents
                    </h4>
                    <el-divider class="mb-0 mt-0"></el-divider>
                    <div
                      class="each-document"
                      v-for="(doc,index) of newlySelectedDocuments.requested"
                      :key="index"
                    >
                      <el-button
                        circle
                        class="float-right"
                        size="mini"
                        type="danger"
                        icon="el-icon-close"
                        @click="removeComapnyDocument('requested',index)"
                      ></el-button>
                      <h4>{{doc.title}}</h4>
                    </div>
                  </el-card>
                </div>
                <div v-else>
                  <h3 class="text-center mt-2 font-weight-normal">No Documents</h3>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import CompanyDocumentsHelper from '@/mixins/CompanyDocumentsHelper'

export default {
  name: "userProfile-UserDocuments",
  props: [],
  mixins: [CompanyDocumentsHelper],
  computed: {
    ...mapGetters("employeeDocuments", ["getEmployeeAllDocuments"])
  },
  data() {
    return {
      documentsData: [],
      loading: false,
      workflowCode: "",
      documentsDataLoading: false,
      userId: ""
    };
  },
  mounted() {
    this.fetchEmployeeDocumentsData();
  },
  methods: {
    submit() {},
    async fetchEmployeeDocumentsData() {
      this.loading = true;
      this.userId = this.$route.params.user_id;
      this.documentsData = [];

      await this.$store.dispatch(
        "employeeDocuments/fetchEmployeeAllDocuments",
        {
          user_id: this.userId
        }
      );

      this.documentsData = this.getEmployeeAllDocuments.data;

      this.loading = false;
    },

    viewDoc(doc) {
      const documentType = doc.type || doc.document_type

      if (documentType == "CUSTOM") {
        if (doc.workflow_data_id) {
          this.$router.push({
            name: "execute-custom-document-user",
            params: {
              document_id: doc._id,
              configurable_document_id: doc.configurable_document_id,
              workflow_data_id: doc.workflow_data_id,
            },
            query: {
              employee_document_id: doc.employee_document_id,
              workflow_step_id: this.workflowStepId
            }
          });
        } else {
          this.$router.push({
            name: "execute-custom-document",
            params: {
              document_id: doc._id,
              configurable_document_id: doc.configurable_document_id
            },
            query: {
              workflow_step_id: this.workflowStepId
            }
          });
        }
      }
      if (documentType == "STATIC") {
        if (doc.workflow_data_id) {
          this.$router.push({
            name: "execute-static-document-user",
            params: {
              document_id: doc._id,
              workflow_data_id: doc.workflow_data_id
            },
            query: {
              employee_document_id: doc.employee_document_id,
              workflow_step_id: this.workflowStepId
            }
          });
        } else {
          this.$router.push({
            name: "execute-static-document",
            params: {
              document_id: doc._id
            },
            query: {
              workflow_step_id: this.workflowStepId
            }
          });
        }
      } 
      if (documentType == "STANDARD") {
        if (doc.workflow_data_id) {
          this.$router.push({
            name: "execute-standard-document-user",
            params: {
              document_slug: "i9-form",
              workflow_data_id: doc.workflow_data_id
            },
            query: {
              employee_document_id: doc.employee_document_id,
              workflow_step_id: this.workflowStepId
            }
          });
        } else {
          this.$router.push({
            name: "execute-standard-document",
            params: {
              document_slug: "i9-form"
            },
            query: {
              workflow_step_id: this.workflowStepId
            }
          });
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("employeeDocuments/setEmployeeAllDocuments",null,{root:true})
  }
};
</script>
<style lang="scss">
.company-documents-status {
  min-height: calc(100vh - 190px);
  .el-table {
    tr {
      th {
        &:first-child {
          .cell {
            text-align: left;
          }
        }
      }
      td:last-child {
        .cell {
          .el-button {
            color: blue;
          }
        }
      }
    }
  }
}
</style>